import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  faChartPie,
  faHospitalUser,
  faLayerGroup,
  faLocationDot,
  faMedkit,
  faSchool,
  faSlidersH,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  is_dynamic?: boolean;
  permission?: string;
  activityId?: number;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon?: IconProp;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  permission?: string;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    active: true,
    icon: faChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'overview',
        path: '/',
        pathName: 'default-dashboard',
        permission: 'actor-activitytype-list',
        active: true,
        icon: faChartPie
      }
    ]
  },
  {
    label: 'dashboard',
    icon: faHospitalUser,
    permission: 'patient-list,actor-venue-list',
    pages: [
      {
        name: 'patient_database',
        icon: faHospitalUser,
        path: '/patient-management/patient',
        pathName: 'activity-type',
        permission: 'patient-list',
        active: true
      },
      {
        name: 'venue_management',
        icon: faSchool,
        path: '/actor-management/venue',
        pathName: 'venue',
        permission: 'actor-venue-list',
        active: true
      }
    ]
  },
  {
    label: 'System Admin',
    icon: faHospitalUser,
    permission:
      'mdm-location-list,actor-activitytype-update,actor-list,auth-tenant-user-list',
    pages: [
      {
        name: 'activity_management',
        path: '/actor-management/activity-type',
        pathName: 'activity-type',
        icon: faMedkit,
        active: true,
        permission: 'actor-activitytype-update'
      },
      {
        name: 'actor_management',
        path: '/actor-management/actor',
        icon: faLayerGroup,
        pathName: 'actor',
        active: true,
        permission: 'actor-list'
      },
      {
        name: 'geo_data',
        icon: faLocationDot,
        path: '/mdm/location',
        pathName: 'mdm-location',
        permission: 'mdm-location-list',
        active: true
      },
      {
        name: 'user_management',
        icon: faUsers,
        permission: 'auth-tenant-user-list,auth-role-list',
        pages: [
          {
            name: 'users',
            path: '/user-management/user',
            pathName: 'user',
            permission: 'auth-tenant-user-list',
            active: true
          },
          {
            name: 'roles',
            path: '/user-management/role',
            pathName: 'role',
            permission: 'auth-role-list',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: '',
    icon: faHospitalUser,
    pages: [
      {
        name: 'settings',
        icon: faSlidersH,
        path: '/settings/settings',
        pathName: 'settings',
        permission: 'auth-tenant-setting-list',
        active: true
      },
      {
        name: 'profile',
        icon: faUser,
        path: '/profile',
        pathName: 'settings',
        active: true
      }
    ]
  }
];
