import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';

import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { SelectOptionType } from '../../../types';
import ReactSelect from '../../base/ReactSelect';
import { PatientType } from '../../../types/patientManagement';
import { GeoLocationLevelType, GeoLocationType } from '../../../types/mdm';
import { PatientFormType } from '../patient-management/PatientForm';
import { VenueFormType } from '../actor-management/VenueForm';
import { ActorType, Venue } from '../../../types/actorManagement';
import { HealthCenterFormType } from '../health-center-management/HealthCenterForm';
import { useParams } from 'react-router-dom';
import useActorHook from '../../../hooks/actor-management/useActorHook';

export interface DynamicLocationFormProps {
  formData: PatientType | Venue | ActorType;
  actorLocation?: string[];

  locations: GeoLocationType[];
  locationLevels: GeoLocationLevelType[];

  compact?: boolean;
}

export interface LocationSelectOption {
  level?: string;
  option: SelectOptionType | null;
  options: SelectOptionType[];
}

const DynamicLocationForm = ({
  actorLocation,
  locations,
  locationLevels,
  formData,
  compact
}: DynamicLocationFormProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const [locationOption, setLocationOption] = useState<LocationSelectOption[]>(
    []
  );
  const { values, setFieldValue } = useFormikContext<
    PatientFormType | VenueFormType | HealthCenterFormType
  >();
  const [initLocationOption, setInitLocationOption] = useState<
    LocationSelectOption[]
  >([]);
  // const initialValues = useMemo(() => {
  //   return values
  // },[initLocationOption])
  const { activityTypeId } = useParams();
  const { selectedActor } = useActorHook();
  const isDisabled = useMemo(() => {
    return !!(selectedActor && activityTypeId);
  }, [selectedActor, activityTypeId]);
  useEffect(() => {
    setLocationItem();
  }, [locations, locationLevels]);

  useEffect(() => {
    if (typeof actorLocation == 'undefined') {
      if (formData.location && formData.location?.length > 0) {
        setLocationToSelect(formData.location);
      }
    } else {
      if (actorLocation?.length > 0) {
        setLocationToSelect(actorLocation);
      } else {
        // setLocationToSelect([]);

        initLocationOption.forEach(async (data, j) => {
          await setFieldValue(`locationSelect.${j}`, null);
        });
      }
    }
  }, [actorLocation, formData, initLocationOption]);

  const setLocationToSelect = (selectedLocation: string[]) => {
    let tempSelectedValueAndOption: LocationSelectOption[] = JSON.parse(
      JSON.stringify(initLocationOption)
    );
    tempSelectedValueAndOption = tempSelectedValueAndOption.map((data, i) => {
      if (i > selectedLocation.length) {
        data.options = [];
      }

      return data;
    });

    values.locationSelect = locations
      .filter(data => {
        if (data?.code && Array.isArray(selectedLocation)) {
          return selectedLocation.includes(data.code);
        }
      })
      .sort(
        (a, b) =>
          selectedLocation.indexOf(a.code as string) -
          selectedLocation.indexOf(b.code as string)
      )
      .map((data, index) => {
        const option = {
          label: data.name,
          value: data.id,
          code: data.code || null,
          parent_id: data.parent_id || null
        };

        setValueAndOption(tempSelectedValueAndOption, option, index).catch();
        return option;
      });
    setLocationOption(tempSelectedValueAndOption);
  };

  const noneOptions: SelectOptionType = { label: 'None', value: '' };
  const setLocationItem = () => {
    const initOptions: LocationSelectOption[] = locationLevels.map(
      (locationLevel, index) => {
        const options: SelectOptionType[] = locations
          .filter(data => data.location_level_id == locationLevel.id)
          .map(data => {
            return {
              label: data.name,
              value: data.id,
              code: data.code || null,
              parent_id: data.parent_id || null
            };
          });
        return {
          level: locationLevel.name || '',
          option: values.locationSelect?.[index] || null,
          options: options
        };
      }
    );

    setInitLocationOption(initOptions);
    const tempLocationOption: LocationSelectOption[] = JSON.parse(
      JSON.stringify(initOptions)
    );
    setLocationOption(
      tempLocationOption.map((data, i) => {
        if (i > 0) data.options = [];
        return data;
      })
    );
  };
  const handleParentChange = async (option: unknown | null, i: number) => {
    const selectedOption = option as SelectOptionType;
    const tempOptions: LocationSelectOption[] = JSON.parse(
      JSON.stringify(locationOption)
    );
    await setValueAndOption(tempOptions, selectedOption, i);
  };
  const setValueAndOption = async (
    tempOptions: LocationSelectOption[],
    option: SelectOptionType,
    i: number
  ) => {
    tempOptions = tempOptions.map((data, j) => {
      if (j > i) {
        data.options = initLocationOption[j].options.filter(
          data => data.parent_id == option.value
        );
        setFieldValue(`locationSelect.${j}`, null);
      }

      return data;
    });
    await setFieldValue(`locationSelect.${i}`, option);
    setLocationOption(tempOptions);
  };

  return (
    <FieldArray
      name="locations"
      render={() => {
        const locationSelectValues = values.locationSelect || [];
        return (
          <>
            {locationOption.map((data, i) => (
              <Col md={compact ? 4 : 6} sm={12} key={`level-${i}`}>
                <Form.Group className="mb-3 text-start">
                  <Form.Label htmlFor="locationSelect">{data.level}</Form.Label>
                  <ReactSelect
                    options={
                      locationOption?.[i].options.length > 0
                        ? [noneOptions].concat(
                            locationOption?.[i].options || []
                          )
                        : []
                    }
                    isDisabled={isDisabled}
                    value={locationSelectValues[i] || noneOptions}
                    id={`locationSelect.${i}.label`}
                    name={`locationSelect.${i}.label`}
                    onChange={option => handleParentChange(option, i)}
                    placeholder={`${t('common.label.select')} ${t(
                      'common.label.location'
                    ).toLowerCase()} ....`}
                  />

                  <div className="text-danger">
                    <ErrorMessage name="locationSelect">
                      {() => (
                        <>
                          {t('component.form.validation.mandatory.select', {
                            field: locationLevels?.[i]
                              ? locationLevels?.[i].name.toLowerCase()
                              : 'location'
                          })}
                        </>
                      )}
                    </ErrorMessage>
                  </div>
                </Form.Group>
              </Col>
            ))}
          </>
        );
      }}
    />
  );
};

export default DynamicLocationForm;
