import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { Route } from 'sitemap';
import { capitalize } from 'helpers/utils';
import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCaretRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavbarVerticalCollapse } from './NavbarVerticalCollapseProvider';
import Badge from 'components/base/Badge';
import { useAppContext } from 'providers/AppProvider';
import { useTranslation } from 'react-i18next';
import { fastIconList } from 'utils/fasIconList';
import { confirmAlert } from '../../common/ConfirmAlert';
import useActorHook from '../../../hooks/actor-management/useActorHook';
import ChooseModal from '../../dashboard/ChooseModal';
import { ActorType } from '../../../types/actorManagement';

interface NavbarVerticalMenuProps {
  routes: Route[];
  level: number;
}

interface NavItemProps {
  route: Route;
  level: number;
}

const NavItem = ({ route, level }: NavItemProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  const {
    config: { isNavbarVerticalCollapsed }
  } = useAppContext();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();

  const foundIcon = fastIconList.find(icon => icon.name === route?.icon);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedActor, selectActor, clearSelectedActor } = useActorHook();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityId, setActivityId] = useState<number>();
  useEffect(() => {
    setIsModalOpen(false);
  }, [selectedActor]);

  const handleActorChange = (
    actor: ActorType,
    isActorClick: boolean = true
  ) => {
    selectActor(actor);
    isActorClick && navigate('/');
  };

  const handleActorRemove = () => {
    clearSelectedActor();
    navigate('/');
  };
  const handleNavClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: Route
  ) => {
    event.preventDefault(); // Prevent default link behavior
    const isEncounterSection = pathname.includes('encounter-tab');

    if (!selectedActor && route?.is_dynamic && route?.activityId) {
      setActivityId(route?.activityId);
      setIsModalOpen(true);
    }

    if (isEncounterSection) {
      const confirmNavigation = await confirmAlert({
        icon: faInfoCircle,
        title: ``,
        cancelLabel: `yes`,
        confirmLabel: `no`,
        cancelVariant: 'subtle-secondary',
        confirmVariant: 'success',
        message: `${t('component.dialog.encounter.unsaved.body')}`
      });

      if (!confirmNavigation) {
        level === 1 && setOpenItems(openItems.map(() => ''));
        navigate(route.path as string);
      }
    } else {
      navigate(route.path as string);
    }
  };
  return (
    <>
      <Nav.Item as="li">
        <NavLink
          to={route.path ? route.path : '#!'}
          className={({ isActive }) =>
            classNames('nav-link', {
              'label-1': level === 1,
              active: isActive && route.path !== '#!'
            })
          }
          onClick={e => {
            e.preventDefault();
            handleNavClick(e, route);
          }}
        >
          <div
            className={classNames('d-flex align-items-center', {
              'text-light': !route.active
            })}
          >
            {route.icon ? (
              <>
                <span
                  className={classNames('nav-link-icon', {
                    new: route.new || route.hasNew
                  })}
                >
                  <FontAwesomeIcon
                    icon={(foundIcon?.icon || route.icon) as IconDefinition}
                  />
                </span>
                <span className="nav-link-text-wrapper d-flex justify-content-between w-100">
                  <span className="nav-link-text">
                    {capitalize(
                      route.is_dynamic
                        ? route.name
                        : t(`common.label.${route.name}`)
                    )}
                  </span>
                  {route.new && !isNavbarVerticalCollapsed && (
                    <Badge variant="phoenix" bg="info" className="ms-2">
                      New
                    </Badge>
                  )}
                </span>
              </>
            ) : (
              <>
                <span>{capitalize(t(`common.label.${route.name}`))}</span>
                {route.new && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </>
            )}
          </div>
        </NavLink>
      </Nav.Item>
      <ChooseModal
        modalOpen={isModalOpen}
        activityId={activityId as number}
        setModalOpen={setIsModalOpen}
        selectedActor={selectedActor}
        onSelectedActorChange={handleActorChange}
        handleActorRemove={handleActorRemove}
      />
    </>
  );
};

const CollapsableNavItem = ({ route, level }: NavItemProps) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { pathname } = useLocation();
  const { setOpenItems, openItems } = useNavbarVerticalCollapse();
  const {
    config: { isNavbarVerticalCollapsed }
  } = useAppContext();

  const openCollapse = (childrens: Route[] = []) => {
    const checkLink = (children: Route) => {
      if (`${children.path}` === pathname) {
        return true;
      }
      return children.pages && children.pages.some(checkLink);
    };
    return childrens.some(checkLink);
  };

  const updateOpenItems = (name: string) => {
    const updatedOpenItems = [...openItems];
    updatedOpenItems[level] = name;
    updatedOpenItems.forEach((item, index) => {
      if (index > level) {
        updatedOpenItems[index] = '';
      }
    });
    setOpenItems(updatedOpenItems);
  };

  useEffect(() => {
    if (openCollapse(route.pages)) {
      updateOpenItems(route.name);
    }
  }, []);

  return (
    <>
      <Nav.Link
        onClick={() => {
          if (route.name === openItems[level]) {
            updateOpenItems('');
          } else {
            updateOpenItems(route.name);
          }
        }}
        className={classNames('dropdown-indicator', {
          'label-1': level === 1,
          collapsed: openItems[level] !== route.name
        })}
        aria-expanded={openItems[level] === route.name}
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          {level === 1 && (
            <span
              className={classNames('nav-link-icon', {
                new: route.new || route.hasNew
              })}
            >
              <FontAwesomeIcon icon={route.icon as Icon} />
            </span>
          )}
          <span className="d-flex align-items-center w-100">
            <span
              className={classNames('nav-link-text', {
                new: route.hasNew
              })}
            >
              {capitalize(t(`common.label.${route.name}`))}
            </span>
            {(!isNavbarVerticalCollapsed || level !== 1) && route.new && (
              <Badge variant="phoenix" bg="info" className="ms-2">
                New
              </Badge>
            )}
          </span>
          <span className="dropdown-indicator-icon">
            <FontAwesomeIcon icon={faCaretRight} />
          </span>
        </div>
      </Nav.Link>
      <div
        className={classNames('parent-wrapper', {
          'label-1': level === 1
        })}
      >
        <Collapse in={openItems[level] === route.name} className="nav parent">
          <div>
            {level === 1 && (
              <div className="collapsed-nav-item-title d-none">
                {isNavbarVerticalCollapsed && route.new && (
                  <Badge variant="phoenix" bg="info" className="ms-2">
                    New
                  </Badge>
                )}
              </div>
            )}
            <NavbarVerticalMenu routes={route.pages || []} level={level + 1} />
          </div>
        </Collapse>
      </div>
    </>
  );
};

const NavbarVerticalMenu = ({ routes, level }: NavbarVerticalMenuProps) => {
  const { pathname } = useLocation();
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();

  const matchingRoute = routes.find(route => pathname === route.path);

  if (matchingRoute) {
    const pageTitle = matchingRoute.is_dynamic
      ? matchingRoute.name
      : t(`common.label.${matchingRoute.name}`);
    document.title = `${process.env.REACT_APP_NAME} | ${pageTitle}`;
  }

  return (
    <>
      {routes.map(route => (
        <div key={route.name} className={level === 1 ? 'nav-item-wrapper' : ''}>
          {route.pages ? (
            <CollapsableNavItem route={route} level={level} />
          ) : (
            <NavItem route={route} level={level} />
          )}
        </div>
      ))}
    </>
  );
};

export default NavbarVerticalMenu;
