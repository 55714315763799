import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  Card,
  Col,
  Form,
  InputGroup,
  Offcanvas,
  OffcanvasProps,
  Row,
  Table
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  EncounterHistoryType,
  EncounterPeriodontalType,
  EncounterReferralType,
  EncounterScreeningType,
  EncounterTreatmentType,
  EncounterType,
  TreatmentTypesType
} from '../../../types/encounterManagement';
import {
  faArrowDownWideShort,
  faCheckSquare,
  faClockRotateLeft,
  faPrescription,
  faRefresh,
  faSquareXmark,
  faStethoscope
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../base/Button';
import { PatientType } from '../../../types/patientManagement';
import {
  getFullAltName,
  getFullName,
  showOrHideField,
  showOrHideFieldResource
} from '../../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  cariesRisks,
  hmisCodes,
  periodontalTeeths,
  xrayTypes
} from '../../../data/encounterManagementData';
import useFormOptionHook from '../../../hooks/settings/useFormOption';
import { FormOptionType } from '../../../types/settings';
import { ActivityType } from '../../../types/actorManagement';
import useVenueHook from '../../../hooks/actor-management/useVenueHook';
import { checkScope } from '../../../helpers/auth';
import useTreatmentType from '../../../hooks/encounter-management/useTreatmentType';
import useEncounterHook from '../../../hooks/encounter-management/useEncounterHook';
import TreatmentForm from '../../forms/encounter-management/TreatmentForm';
import XrayImagePreview from '../../common/XrayImagePreview';
import CheckboxLabelItem from '../../common/CheckboxLabelItem';
import useEncounterScreeningOptionHook from '../../../hooks/encounter-management/useEncounterScreeningOptionHook';
import { FormSetting } from '../../../types';

interface EncounterDetailProps {
  encounter: EncounterType;
  patient: PatientType;
  activity_type: ActivityType;
  encounter_reason: string;

  onClose: () => void;
  onEdit: (id: number) => void;
  offCanvas: OffcanvasProps;
}

const EncounterDetail: React.FC<EncounterDetailProps> = ({
  encounter,
  activity_type,
  encounter_reason,
  patient,
  offCanvas,
  onEdit,
  onClose
}) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const history = useMemo(() => {
    return encounter ? encounter.history : null;
  }, [encounter]);
  // console.log(encounter);
  const screening = useMemo(() => {
    return encounter ? encounter.screening : null;
  }, [encounter]);
  const periodontal = useMemo(() => {
    return encounter ? encounter.periodontal_treatment : null;
  }, [encounter]);
  const diagnostic = useMemo(() => {
    return encounter ? encounter.diagnostic_treatment : null;
  }, [encounter]);

  const treatment = useMemo(() => {
    return encounter ? encounter.treatments : null;
  }, [encounter]);
  const referral = useMemo(() => {
    return encounter ? encounter.referral : null;
  }, [encounter]);
  const { fetchAllFormOption, formOptions } = useFormOptionHook();
  const { problemOptions, adviceOptions } = useEncounterScreeningOptionHook();
  const [plaqueScoreOptions, setPlaqueScoreOptions] = useState<
    FormOptionType[]
  >([]);
  const [occurrenceOptions, setOccurrenceOptions] = useState<FormOptionType[]>(
    []
  );
  const { fetchOneVenue, venue } = useVenueHook();
  const { fetchAllTreatmentType } = useTreatmentType();
  const [treatmentTypes, setTreatmentTypes] = useState<TreatmentTypesType[]>(
    []
  );
  const {
    fetchAllTabSettingEncounter,
    encounterTabFormSettings,
    fetchAllFormSettingEncounter
  } = useEncounterHook();
  const [screeningFormSettings, setScreeningFormSettings] = useState<
    FormSetting[]
  >([]);
  const [referralFormSettings, setReferralFormSettings] = useState<
    FormSetting[]
  >([]);
  useEffect(() => {
    fetchTreatmentTypeItems();
    fetchAllFormOption().catch(e => console.log(e));
    if (encounter.school_id) fetchOneVenueItem(encounter.school_id as number);
    if (encounterTabFormSettings.length <= 0) {
      fetchAllTabSettingEncounter('encounter_tabs')
        .then()
        .catch(e => console.log(e));
    }
    if (screeningFormSettings.length <= 0) {
      fetchAllFormSettingEncounter('screenings')
        .then(results => setScreeningFormSettings(results || []))
        .catch(e => console.log(e));
    }
    if (referralFormSettings.length <= 0) {
      fetchAllFormSettingEncounter('referral')
        .then(results => setReferralFormSettings(results || []))
        .catch(e => console.log(e));
    }
  }, [encounter]);

  useEffect(() => {
    if (formOptions.length > 0) {
      setPlaqueScoreOptions(
        formOptions.filter(data => data.slug == 'plaque-score')
      );
      setOccurrenceOptions(
        formOptions.filter(data => data.slug == 'occurrence')
      );
    }
  }, [formOptions]);

  const fetchOneVenueItem = (id: number) => {
    fetchOneVenue(id).catch(e => {
      console.log(e);
    });
  };

  const fetchTreatmentTypeItems = () => {
    if (treatmentTypes.length <= 0) {
      fetchAllTreatmentType()
        .then(results => {
          setTreatmentTypes(results);
        })
        .catch(e => console.log(e));
    }
  };

  const getPlaqueScoreLabel = (score: string) => {
    const filterScores = plaqueScoreOptions.filter(
      data => data.value === score
    );
    if (filterScores.length > 0) {
      return filterScores[0].label;
    } else {
      return score;
    }
  };
  const getOccurrenceLabel = (score: string) => {
    const filterScores = occurrenceOptions.filter(data => data.value === score);
    if (filterScores.length > 0) {
      return filterScores[0].label;
    } else {
      return score;
    }
  };
  const getCariesRisLabel = (value: string) => {
    const filters = cariesRisks.filter(data => data.value == value);

    if (filters.length > 0) {
      return filters[0].label;
    } else {
      return value;
    }
  };
  const getHMISCodeLabel = (value: string) => {
    const filters = hmisCodes.filter(data => data.value == value);
    if (filters.length > 0) {
      return filters[0].label;
    } else {
      return value;
    }
  };
  const showOrHideSection = (encounterType: string) => {
    const filter = encounterTabFormSettings.filter(
      data => data.selected && data.value.toLowerCase() == encounterType
    );
    return filter.length > 0;
  };
  const renderText = (label: string, value: string | undefined) => {
    return (
      <>
        <h5> {t('common.label.' + label)}</h5>
        <p title={value || 'N/A'} className="text-break text-truncate">
          {value ? value : 'N/A'}
        </p>
      </>
    );
  };
  const renderTextInline = (label: string, value: string | undefined) => {
    return (
      <div className="d-flex align-items-center gap-2">
        <b>{t('common.label.' + label)}:</b>
        <span>{value ? value : 'N/A'}</span>
      </div>
    );
  };
  const renderHistory = () => {
    const checkboxFields = [
      'blood_disorder_bleeding_problem',
      'diabetes',
      'liver_problem',
      'rheumatic_fever',
      'seizures_or_epilepsy',
      'hepatitis_b_or_c',
      'hiv',
      'high_blood_pressure',
      'low_blood_pressure',
      'thyroid_disorder',
      'pregnancy',
      'others',
      'no_underlying_medical_condition',
      'not_taking_any_medicines',
      'no_allergies'
    ];
    return (
      <Row>
        {checkboxFields.map((field, i) => (
          <Col key={`history-check-${i}`} className="mt-2 mb-2" md={4} sm={12}>
            <CheckboxLabelItem
              status={
                (history?.[field as keyof EncounterHistoryType] as string) ||
                'N'
              }
              field={field}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const renderScreening = () => {
    const checkboxTeethFields = [
      'decayed_primary_teeth',
      'decayed_permanent_teeth',
      'missing_primary_teeth',
      'missing_permanent_teeth',
      'filled_primary_teeth',
      'filled_permanent_teeth'
    ].filter(field => showOrHideField(screeningFormSettings, field));
    const checkboxFields = [
      'cavity_permanent_molar_premolar',
      'cavity_permanent_canine_incisor',
      'pain_due_to_reversible_pulpitis',
      'active_infection',
      'needs_sdf',
      'needs_fv',
      'needs_sealant',
      'needs_art_filling',
      'needs_extraction',
      'needs_dentist_or_hygienist_referral'
    ].filter(field => showOrHideField(screeningFormSettings, field));
    const renderAssessmentTypeProblemAdvice = (
      problemOrAdvice: number[],
      options: FormOptionType[]
    ) => {
      let tempOptions: FormOptionType[] = [];

      tempOptions = options.filter(data =>
        problemOrAdvice.includes(parseInt(data.value as string))
      );

      if (tempOptions.length > 0) {
        return (
          <ul>
            {tempOptions.map(v => (
              <li key={v.id}>{v.label}</li>
            ))}
          </ul>
        );
      } else {
        return null;
      }
    };

    return (
      <>
        <Row>
          <Col className="mt-2 mb-2" md={12} sm={12}>
            {renderTextInline(
              'plaque_score',
              getPlaqueScoreLabel(screening?.plaque_score || 'N/A')
            )}
          </Col>
        </Row>

        {showOrHideField(screeningFormSettings, 'screening_assessment_id') && (
          <Row className="mt-4">
            <Col className="mt-2 mb-2" md={6} sm={12}>
              {renderTextInline(
                'caries_risk',
                getCariesRisLabel(screening?.assessment?.caries_risk || 'N/A')
              )}
            </Col>
            <Col className="mt-2 mb-2" md={6} sm={12}>
              {renderTextInline(
                'hmis_code',
                getHMISCodeLabel(screening?.assessment?.hmis_code || 'N/A')
              )}
            </Col>
            <Col md={12}>
              <table className="table">
                <tbody>
                  <tr>
                    <th></th>
                    <th>Problems</th>
                    <th>Advices</th>
                  </tr>
                  <tr>
                    <th>{t('common.label.fluoride')}</th>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.fluoride_problem_advice
                          ?.problem || [],
                        problemOptions.fluoride_problem_advice
                      )}
                    </td>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.fluoride_problem_advice
                          ?.advice || [],
                        adviceOptions.fluoride_problem_advice
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{t('common.label.saliva')}</th>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.saliva_problem_advice?.problem ||
                          [],
                        problemOptions.saliva_problem_advice
                      )}
                    </td>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.saliva_problem_advice?.advice ||
                          [],
                        adviceOptions.saliva_problem_advice
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{t('common.label.plaque')}</th>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.plaque_problem_advice?.problem ||
                          [],
                        problemOptions.plaque_problem_advice
                      )}
                    </td>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.plaque_problem_advice?.advice ||
                          [],
                        adviceOptions.plaque_problem_advice
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{t('common.label.diet')}</th>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.diet_problem_advice?.problem ||
                          [],
                        problemOptions.diet_problem_advice
                      )}
                    </td>
                    <td>
                      {renderAssessmentTypeProblemAdvice(
                        screening?.assessment?.diet_problem_advice?.advice ||
                          [],
                        adviceOptions.diet_problem_advice
                      )}
                      <ul>
                        <li>
                          {
                            screening?.assessment?.diet_problem_advice
                              ?.advice_text
                          }
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        )}

        <Row>
          {checkboxTeethFields.map((field, i) => (
            <Col
              className="mt-2 mb-2"
              md={6}
              sm={12}
              key={`checkboxTeethFields-${i}`}
            >
              {(screening?.[field as keyof EncounterScreeningType] as number) >
              0 ? (
                <>
                  <FontAwesomeIcon
                    icon={faCheckSquare}
                    className={`text-primary me-2`}
                  />

                  <span className="fw-bold">
                    {t(`common.label.${field}`)} -{' '}
                    {screening?.[field as keyof EncounterScreeningType]}
                  </span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faSquareXmark}
                    className={`text-secondary-darker me-2`}
                  />
                  <span>No {t(`common.label.${field}`)}</span>
                </>
              )}
            </Col>
          ))}
          {checkboxFields.map((field, i) => (
            <Col
              key={`screening-check-${i}`}
              className="mt-2 mb-2"
              md={6}
              sm={12}
            >
              <CheckboxLabelItem
                status={
                  (screening?.[
                    field as keyof EncounterScreeningType
                  ] as string) || 'N'
                }
                field={field}
              />
            </Col>
          ))}
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <h5> {t('common.label.screening_card_title_2')}</h5>
            <Table bordered hover size="sm">
              <tbody>
                <tr>
                  <td> {t('common.label.pain_in_mouth_or_teeth')}</td>
                  <th>
                    {getOccurrenceLabel(
                      screening?.pain_in_mouth_or_teeth || 'N/A'
                    )}
                  </th>
                </tr>
                <tr>
                  <td>{t('common.label.sores_in_mouth')}</td>
                  <th>
                    {getOccurrenceLabel(screening?.sores_in_mouth || 'N/A')}
                  </th>
                </tr>
                <tr>
                  <td> {t('common.label.bad_breath')}</td>
                  <th>{getOccurrenceLabel(screening?.bad_breath || 'N/A')}</th>
                </tr>
                <tr>
                  <td> {t('common.label.food_stuck_betwn_teeth')}</td>
                  <th>
                    {getOccurrenceLabel(
                      screening?.food_stuck_betwn_teeth || 'N/A'
                    )}
                  </th>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={6} sm={12}>
            {renderText('comments', screening?.comments)}
          </Col>
        </Row>
      </>
    );
  };
  const renderPeriodontal = () => {
    const checkboxFields = ['hand_scaling', 'ultrasonic_scaling'];
    return (
      <>
        <Row>
          {periodontalTeeths.map(teeth => (
            <Col md={4} sm={12} key={teeth}>
              <InputGroup className="mb-3 text-start">
                <InputGroup.Text id="basic-addon1">
                  {t(`common.label.${teeth}`)}
                </InputGroup.Text>
                <Form.Control
                  id="diagnosis"
                  type="number"
                  disabled={true}
                  value={
                    periodontal?.[teeth as keyof EncounterPeriodontalType] || 0
                  }
                />
              </InputGroup>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>{renderText('diagnosis', periodontal?.diagnosis)}</Col>
        </Row>
        <Row>
          <h5> {t('common.label.treatment')}</h5>
          {checkboxFields.map((field, i) => (
            <Col key={`treatment-check-${i}`} md={12} className="mt-1 mb-1">
              <CheckboxLabelItem
                status={
                  (periodontal?.[
                    field as keyof EncounterPeriodontalType
                  ] as string) || 'N'
                }
                field={field}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>{renderText('others', periodontal?.other_treatment)}</Col>
          <Col>{renderText('notes', periodontal?.notes)}</Col>
        </Row>
      </>
    );
  };
  const renderDiagnostic = () => {
    return (
      <>
        <Row>
          <Col md={6}>
            <Row>
              {xrayTypes.map((data, i) => (
                <Col key={`xrayTypes-${i}`} md={12} className="mt-1 mb-1">
                  <CheckboxLabelItem
                    status={
                      diagnostic?.x_ray_type
                        ? diagnostic?.x_ray_type.includes(data.value)
                          ? 'Y'
                          : 'N'
                        : 'N'
                    }
                    field={data.value}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={6}>
            <h5> {t('common.label.diagnostic_card_title_3')}</h5>
            <div className="d-flex gap-1 p-1">
              <p className="w-30">
                {t('common.label.lesions_mouth_section')}:&nbsp;
              </p>
              <p
                className="text-break text-truncate d-block w-75 fw-bold"
                title={diagnostic?.lesions_mouth_section}
              >
                {diagnostic?.lesions_mouth_section || 'N/A'}
              </p>
            </div>
            <div className="d-flex gap-1  p-1">
              <p className="w-30">
                {t('common.label.lesions_description')}:&nbsp;
              </p>
              <p
                className="text-break text-truncate d-block w-75 fw-bold"
                title={diagnostic?.lesions_description}
              >
                {diagnostic?.lesions_description || 'N/A'}
              </p>
            </div>

            <Col>{renderText('comments', diagnostic?.other_special_test)}</Col>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h5> {t('common.label.x_ray_files')}</h5>
          </Col>
          <Col md={12}>
            <XrayImagePreview images={diagnostic?.images || []} />
          </Col>
        </Row>
      </>
    );
  };
  const renderTreatment = () => {
    const checkboxFields = [
      'whole_mouth_sdf',
      'fv_applied'
      // 'treatment_plan_completed'
    ];
    return (
      <>
        <Row>
          <Col md={12}>
            <TreatmentForm
              treatment={encounter?.treatments || null}
              activityType={activity_type}
              isView={true}
            />
          </Col>
        </Row>
        <Row>
          {checkboxFields.map((field, i) => (
            <Col key={`treatment-check-${i}`} className="mt-2 mb-2" md={12}>
              <CheckboxLabelItem
                status={
                  (treatment?.[
                    field as keyof EncounterTreatmentType
                  ] as string) || 'N'
                }
                field={field}
              />
            </Col>
          ))}

          {showOrHideFieldResource('OHE_CSI') && (
            <Col className="mt-2 mb-2" md={12}>
              <CheckboxLabelItem
                status={
                  (treatment?.[
                    'ohe_given' as keyof EncounterTreatmentType
                  ] as string) || 'N'
                }
                field={'ohe_given'}
              />
            </Col>
          )}
          <Col className="mt-2 mb-2 d-flex justify-content-start" md={12}>
            <CheckboxLabelItem
              status={
                (treatment?.[
                  'treatment_plan_completed' as keyof EncounterTreatmentType
                ] as string) || 'N'
              }
              field={'treatment_plan_completed'}
            />
            <span className={`ms-2`}>
              {' - '}
              {treatment?.treatment_plan_completed == 'Y' ? 'Yes' : 'No'}
            </span>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6}>{renderText('notes', treatment?.notes)}</Col>
        </Row>
      </>
    );
  };
  const renderReferral = () => {
    const checkboxFields = [
      'no_referral',
      'permanent_clinic_recall',
      'hygienist',
      'dentist',
      'general_physician'
    ].filter(field => showOrHideField(referralFormSettings, field));
    return (
      <>
        <Row>
          {checkboxFields.map((field, i) => (
            <Col
              key={`referral-check-${i}`}
              className="mt-2 mb-2"
              md={6}
              sm={12}
            >
              <CheckboxLabelItem
                status={
                  (referral?.[
                    field as keyof EncounterReferralType
                  ] as string) || 'N'
                }
                field={field}
              />
            </Col>
          ))}
          <Col className="mt-2">
            <CheckboxLabelItem
              status={(referral?.others as string) || 'N'}
              field="others"
            />
            <p
              title={referral?.other_text || 'N/A'}
              className="text-break text-truncate"
            >
              {referral?.other_text ? referral?.other_text : 'N/A'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {renderText('recall_date', referral?.recall_date)}
            {renderText('recall_time', referral?.recall_time)}
          </Col>
          <Col md={6}>
            {renderText(
              'reason_for_recall',
              referral?.reason_for_recall
                ? t('common.label.' + referral?.reason_for_recall)
                : ''
            )}
          </Col>
        </Row>
      </>
    );
  };
  const rolePrefix = 'encounter-tabs';
  const items = [
    {
      title: `${t('common.label.history')}`,
      value: 'history',
      role: `${rolePrefix}-history`,
      icon: faClockRotateLeft,
      hierarchy: 1,
      body: renderHistory()
    },
    {
      title: `${t('common.label.screening')}`,
      value: 'screening',
      role: `${rolePrefix}-screening`,
      icon: faArrowDownWideShort,
      hierarchy: 2,
      body: renderScreening()
    },
    {
      title: `${t('common.label.periodontal')}`,
      value: 'periodontal',
      role: `${rolePrefix}-periodontal`,
      icon: faArrowDownWideShort,
      hierarchy: 3,
      body: renderPeriodontal()
    },
    {
      title: `${t('common.label.diagnostic')}`,
      value: 'diagnostic',
      role: `${rolePrefix}-diagnostic`,
      icon: faStethoscope,
      hierarchy: 4,
      body: renderDiagnostic()
    },
    {
      title: `${t('common.label.treatment')}`,
      value: 'treatment',
      role: `${rolePrefix}-treatment`,
      icon: faPrescription,
      hierarchy: 5,
      body: renderTreatment()
    },
    {
      title: `${t('common.label.referral')}`,
      value: 'referral',
      role: `${rolePrefix}-referral`,
      icon: faRefresh,
      hierarchy: 6,
      body: renderReferral()
    }
  ];
  const accordionItems = useMemo(() => {
    return items
      .filter(item => checkScope(item.role))
      .map(item => {
        encounterTabFormSettings
          .filter(data => data.selected)
          .forEach(data => {
            if (data.value.toLowerCase() == item.value) {
              item.hierarchy = data.hierarchy;
            }
          });
        return item;
      })
      .filter(data => showOrHideSection(data.value))
      .sort((a, b) => a.hierarchy - b.hierarchy);
  }, [encounterTabFormSettings, occurrenceOptions, plaqueScoreOptions]);

  // useEffect(()=>{
  //   accordionItems.forEach((data, i) => {
  //
  //
  //     if (i == 0) {
  //
  //       setDefaultAccordionKey(data.value);
  //     }
  //     return data;
  //   });
  // },[accordionItems])
  return (
    <Offcanvas style={{ width: '70%' }} onHide={onClose} {...offCanvas}>
      <Offcanvas.Header className="px-6 pt-6 pb-0" closeButton>
        <Offcanvas.Title className="d-flex align-items-center">
          <h4 className="mb-0">{t('page.title.encounter_detail')}</h4>

          {checkScope('encounter-update') && (
            <Button
              variant="link"
              onClick={() => onEdit(encounter.id as number)}
            >
              <span className="fw-bold">
                {getFullName(patient)}&nbsp;
                {getFullAltName(patient) ? `(${getFullAltName(patient)})` : ''}
              </span>
            </Button>
          )}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-6 pb-6">
        <Row className="g-4">
          <Col>
            <Card bg="light" text={'dark'}>
              <Card.Body className="p-3">
                <div className="d-flex gap-12">
                  <div>
                    <p className="mb-2">{t('common.label.activity_type')}</p>
                    <h5>{encounter?.activity_type_name || 'N/A'}</h5>
                  </div>
                  <div>
                    <p className="mb-2">{t('common.label.venue')}</p>
                    <h5>{venue?.name || 'N/A'}</h5>
                  </div>
                  <div>
                    <p className="mb-2">{t('common.label.encounter_reason')}</p>
                    <h5>{encounter_reason || 'N/A'}</h5>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {accordionItems.length > 0 && (
              <Accordion defaultActiveKey={'1'}>
                {accordionItems.map(data => {
                  return (
                    <Accordion.Item
                      key={`encounter-${data.value}`}
                      eventKey={'1'}
                      className="border-top"
                    >
                      <Accordion.Header>
                        <div className="text-secondary-darker fs-7">
                          <FontAwesomeIcon icon={data.icon} className=" me-2" />
                          {data.title}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="mt-2 p-3 border  border-2">
                        {data.body}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            )}
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EncounterDetail;
